export enum StateItemId {
  loader = 'loader',
  spacer = 'spacer',
  notFound = 'not found',
  invalidUrl = 'invalidUrl',
}

export enum ComposeTabId {
  all = 'all',
  postUrl = 'postUrl',
}

export const POST_LOADING_ATTEMPT_LIMIT = 10;
export const POST_LOADING_TOTAL_LIMIT = 1000;
